<script setup>
const props = defineProps({
  end: {
    type: String,
    default: "",
  },
});

// ==================== CONSTANTS ====================

const formattedTime = ref("");
let timerInterval;

// ==================== FUNCTIONS ====================

function calculateTimeLeft() {
  const timeDifference = new Date(props.end) - Date.now();

  if (timeDifference > 0) {
    const daysValue = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursValue = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const minutesValue = Math.floor((timeDifference / (1000 * 60)) % 60);
    const secondsValue = Math.floor((timeDifference / 1000) % 60);

    formattedTime.value = `${formatTime(daysValue)}d ${formatTime(hoursValue)}h ${formatTime(
      minutesValue
    )}m ${formatTime(secondsValue)}s`;
  } else {
    formattedTime.value = "";
  }
}

function formatTime(value) {
  return value.toString().padStart(2, "0");
}

onMounted(() => {
  calculateTimeLeft();
  timerInterval = setInterval(calculateTimeLeft, 1000);
});

// ==================== MOUNT ====================

onUnmounted(() => {
  clearInterval(timerInterval);
});
</script>

<template>
  <div>
    <span><slot></slot></span>
    <span>{{ formattedTime }}</span>
  </div>
</template>
